.PreviewModal {
  overflow: hidden !important;

  .modal-dialog {
    min-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    overflow: hidden;

    .modal-content {
      height: 100%;
    }


    .modal-body {
      width: 100%;
      overflow: auto;
      padding: 0;
    }
  }
}
