.CardItem {
  .label-pill {
    width: fit-content;
    border-radius: var(--bs-border-radius) 0 1rem 0;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
  }

  .continue-btn {
    border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
    font-size: 0.9em;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
